<template>
    <div class="favorite">
        <div class="leftBox">
            <div class="DsVideoDetail-header">
                <div class="headerCover">
                    <img :src="videoInfo.aweme_cover">
                </div>
                <div class="headerCountent">
                    <p class="headerCountentTitle">{{videoInfo.aweme_title}}</p>
                    <div class="headerCountentLike">
                        <i class="iconfont">&#xe62a;</i><span>{{videoInfo.digg_count}}</span>
                        <i style="margin-left: 17px" class="iconfont">&#xe6e7;</i><span>{{videoInfo.share_count}}</span>
                        <i style="margin-left: 17px" class="iconfont">&#xe664;</i><span>{{videoInfo.comment_count}}</span>
                    </div>
                    <div class="headerCountenHot">
                        <span>热词:</span>
                        <span v-if="videoInfo.hasOwnProperty('hot_words_info') && videoInfo.hot_words_info.length === 0">暂无</span>
                        <span v-else class="hotWords" v-for="(item, index) in videoInfo.hot_words_info"
                              :key="`hot_words_info_${index}`">{{item.keyword}}</span>
                    </div>
                    <p class="issueTime">发布时间:{{videoInfo.aweme_create_time}}</p>
                </div>
                <a :href="videoInfo.aweme_url" target="_blank" class="headerBtn">播放视频</a>
            </div>
            <div class="tab_box">
<!--                @tab-click="handleClick"-->
                <el-tabs v-model="ShortTab" type="card">
                    <el-tab-pane label="评论分析" name="first">
                        <detailTab ref="detailTab" v-if="ShortTab === 'first'" :hotWordData="hotWordData"></detailTab>
                    </el-tab-pane>
                    <el-tab-pane label="视频观众分析" name="second">
                        <audiences ref="audiences" v-if="ShortTab === 'second'"></audiences>
                    </el-tab-pane>
                    <el-tab-pane label="商品分析" name="third">
                        <productAnalysis ref="productAnalysis" v-if="ShortTab === 'third'">
                        </productAnalysis>
                    </el-tab-pane>
                    <el-tab-pane label="基础分析" name="fourth">
                        <monitoring ref="monitoring" v-if="ShortTab === 'fourth'">
                        </monitoring>
                    </el-tab-pane>
                </el-tabs>
                <div class="update-content">
                    <span style="color: #999">数据更新时间：{{videoInfo.update_time}}</span>
                    <span @click="updateBtn" class="update-title"><i class="iconfont">&#xe64d;</i>更新数据</span>
                </div>
            </div>
        </div>
        <!--右侧帮助中心-->
<!--        <RightHelpList></RightHelpList>-->
        <EcMineModule :author_id="author_id"></EcMineModule>
    </div>
</template>

<script>
    import { newAccountVideoSummary, newAccountRefreshVideoDetail, newAccountRefreshVideoGoods } from '@/utils/apis.js'

    import audiences from '../../../components/mediumModule/audiences'
    import detailTab from '../../../components/mediumModule/detailTab'
    import productAnalysis from '../../../components/mediumModule/productAnalysis'
    import monitoring from '../../../components/mediumModule/monitoring'
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'
    import EcMineModule from "@/components/mediumModule/EcMineModule";

    export default {
        name: "VideoDetail",
        components: {
            audiences,
            detailTab,
            productAnalysis,
            monitoring,
            RightHelpList,
            EcMineModule
        },
        data() {
            return {
                ShortTab: 'first',
                videoList: {}, // 视频详情
                videoInfo: {},
                author_id: this.$route.query.author_id,
                hotWordData: []
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            // handleClick(tab, event) {
            //     console.log('tab', tab);
            //     console.log('event', event);
            //     if (tab.name === 'first') {
            //         this.$nextTick(() => {
            //             this.$refs.detailTab.getInfo()
            //
            //             let resizeTimer = null;
            //             window.onresize = () => {
            //                 clearTimeout(resizeTimer);
            //                 resizeTimer = setTimeout(() => {
            //                     this.$refs.detailTab.hotWordData.resize()
            //                 }, 100)
            //             }
            //         })
            //     }
            //     if (tab.name === 'second') {
            //         this.$nextTick(() => {
            //             this.$refs.audiences.areaDistribution = []
            //             this.$refs.audiences.getInfo()
            //
            //             let resizeTimer = null;
            //             window.onresize = () => {
            //                 clearTimeout(resizeTimer);
            //                 resizeTimer = setTimeout(() => {
            //                     this.$refs.audiences.ageDistribution.resize()
            //                 }, 100)
            //             }
            //         })
            //     }
            //     if (tab.name === 'third') {
            //         this.$nextTick(() => {
            //             this.$refs.productAnalysis.getVideoDetail()
            //
            //             let resizeTimer = null;
            //             window.onresize = () => {
            //                 clearTimeout(resizeTimer);
            //                 resizeTimer = setTimeout(() => {
            //                     this.$refs.productAnalysis.goodsTrend.resize()
            //                 }, 100)
            //             }
            //         })
            //     }
            //     if (tab.name === 'fourth') {
            //         this.$nextTick(() => {
            //             this.$refs.monitoring.getVideoDetail()
            //
            //             let resizeTimer = null;
            //             window.onresize = () => {
            //                 clearTimeout(resizeTimer);
            //                 resizeTimer = setTimeout(() => {
            //                     this.$refs.monitoring.diggTrend.resize()
            //                     this.$refs.monitoring.shareTrend.resize()
            //                     this.$refs.monitoring.commentTrend.resize()
            //                 }, 100)
            //             }
            //         })
            //     }
            // },
            // 获取视频详情
            getInfo() {
                let params = {
                    aweme_id: this.$route.query.aweme_id,
                }
                newAccountVideoSummary(params).then(res => {
                    if (res.code === 200) {
                        this.videoInfo = res.data.aweme;
                        this.hotWordData = this.videoInfo.hot_words_info;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },

            updateBtn() {
                let p1 = this.updateVideo()
                let p2 = this.updateGoods()
                Promise.all([p1, p2]).then((response) => {
                    if (response[0].code === 200 && response[1].code === 200) {
                        this.$message.success(response[0].msg)
                        this.getInfo()
                        if (this.ShortTab === 'first') {
                            this.$nextTick(() => {
                                this.$refs.detailTab.getInfo()

                                let resizeTimer = null;
                                window.onresize = () => {
                                    clearTimeout(resizeTimer);
                                    resizeTimer = setTimeout(() => {
                                        this.$refs.detailTab.hotWordData.resize()
                                    }, 100)
                                }
                            })
                        }
                        if (this.ShortTab === 'second') {
                            this.$nextTick(() => {
                                this.$refs.audiences.areaDistribution = []
                                this.$refs.audiences.getInfo()

                                let resizeTimer = null;
                                window.onresize = () => {
                                    clearTimeout(resizeTimer);
                                    resizeTimer = setTimeout(() => {
                                        this.$refs.audiences.ageDistribution.resize()
                                    }, 100)
                                }
                            })
                        }
                        if (this.ShortTab === 'third') {
                            this.$nextTick(() => {
                                this.$refs.productAnalysis.getVideoDetail()
                            })
                            let resizeTimer = null;
                            window.onresize = () => {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(() => {
                                    this.$refs.productAnalysis.goodsTrend.resize()
                                }, 100)
                            }
                        }
                        if (this.ShortTab === 'fourth') {
                            this.$nextTick(() => {
                                this.$refs.monitoring.getVideoDetail()
                            })
                            let resizeTimer = null;
                            window.onresize = () => {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(() => {
                                    this.$refs.monitoring.diggTrend.resize()
                                    this.$refs.monitoring.shareTrend.resize()
                                    this.$refs.monitoring.commentTrend.resize()
                                }, 100)
                            }
                        }
                    } else {
                        this.$message.warning(response[0].msg)
                    }
                })
            },
            // 更新视频
            updateVideo() {
                let params = {
                    aweme_id: this.$route.query.aweme_id,
                }
                return new Promise((resolve, reject) => {
                    newAccountRefreshVideoDetail(params).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            // 更新商品
            updateGoods() {
                let params = {
                    aweme_id: this.$route.query.aweme_id,
                }
                return new Promise((resolve, reject) => {
                    newAccountRefreshVideoGoods(params).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })
            },

        }
    }
</script>

<style scoped lang="scss">
    .favorite {
        height: 100%;
        display: flex;

        .leftBox {
            width: 80%;
            display: flex;
            flex-direction: column;

            .DsVideoDetail-header {
                position: relative;
                display: flex;
                background: #ffffff;
                padding: 20px 24px;

                .headerCover {
                    width: 105px;
                    height: 130px;
                    background: #f2f2f2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .headerCountent {
                    width: 1%;
                    flex: 1;
                    overflow: hidden;
                    margin-left: 26px;

                    .headerCountentTitle {
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 16px;
                        width: 432px;
                        height: 44px;
                        margin: 0;
                    }

                    .headerCountentLike {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;

                        i {
                            font-size: 20px;
                            color: #333333;
                            line-height: 21px;
                        }

                        span {
                            color: #333333;
                            font-size: 16px;
                            font-weight: 400;
                            margin-left: 6px;

                        }
                    }

                    .headerCountenHot {
                        overflow: hidden;

                        span {
                            margin-top: 12px;
                        }

                        span:nth-child(1) {
                            color: #999999;
                            font-size: 12px;
                        }

                        .hotWords {
                            display: inline-block;
                            color: #333333;
                            font-size: 12px;
                            padding: 6px 16px;
                            background: #F4F4F4;
                            border-radius: 12px;
                            line-height: 1;
                            font-weight: 400;
                            margin-left: 15px;
                        }
                    }

                    .issueTime {
                        color: #999999;
                        font-size: 12px;
                        line-height: 1;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                }

                .headerBtn {
                    width: 106px;
                    height: 32px;
                    background: linear-gradient(85deg, #FF0000, #FF6060,);
                    border-radius: 2px;
                    color: #ffffff;
                    text-align: center;
                    line-height: 32px;
                    font-size: 14px;
                    position: absolute;
                    right: 2%;
                    top: 9%;
                }
            }

            .tab_box {
                display: flex;
                flex: 1;
                height: 1%;
                margin-top: 20px;
                position: relative;

                ::v-deep .el-tabs {
                    height: 100%;
                    width: 100%;
                    box-shadow: none;
                    border: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    background: transparent;
                }

                ::v-deep .el-tabs__header {
                    border-bottom: none;
                    margin: 0;
                }

                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    padding-top: 20px;
                    min-height: 600px;
                }

                ::v-deep .el-tabs__header .is-active {
                    background: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0824ce;
                    border-radius: 6px 6px 0 0;
                }

                ::v-deep .el-tabs__item {
                    border: none;
                    height: 52px;
                    line-height: 52px;
                }

                ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                    border: none;
                }

                ::v-deep .el-tab-pane {
                    height: 100%;
                }

                .update-content {
                    height: 20px;
                    position: absolute;
                    top: 14px;
                    right: 0;

                    .update-title {
                        margin: 0 10px;
                        font-size: 14px;
                        cursor: pointer;

                        i {
                            color: #0824ce;
                            margin-right: 10px;
                        }

                        &:hover {
                            color: #1D33E1;
                        }
                    }
                }
            }
        }
    }

</style>