<template>
    <div class="fan-analysis">
        <div class="analysis-top">
            <div class="analysis-left">
                <div class="gender-distribution">
                    <span class="title">性别分布</span>
                    <div class="no-data" v-if="gender_summary.length === 0">暂无数据</div>
                    <div class="distribution-chart" v-else>
                        <div class="sex-box">
                            <template v-for="(sexItem, sexIndex) in gender_summary">
                                <template v-if="sexItem.rate">
                                <div class="sex-item" :style="{width: sexItem.rate + '%'}" :key="`gender_summary_${sexIndex}`">
                                    <div class="text">{{sexItem.rate}}%</div>
                                    <div class="bar" :style="{background: sexItem.keyword === '女生' ? '#ff6a6a' : sexItem.keyword === '男生' ? '#564cec' : '#42D7AB'}"></div>
                                    <div class="bottom">
                                        <i class="iconfont"
                                           :style="{color: sexItem.keyword === '女生' ? '#ff6a6a' : sexItem.keyword === '男生' ? '#564cec' : '#42D7AB'}">
                                            {{sexItem.keyword === '女生' ? '&#xe60e;' : sexItem.keyword === '男生' ? '&#xe6a6;' : '&#xe682;'}}
                                        </i>
                                        <div class="text">{{sexItem.keyword}}</div>
                                    </div>
                                </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="analysis-right">
                <div class="age-distribution">
                    <span class="title">年龄分布</span>
                    <div class="no-data" v-show="!showAgeData">暂无数据</div>
<!--                    <div v-show="showAgeData">-->
                    <div id="ageDistribution" style="width: 100%; height: 300px"></div>
<!--                    </div>-->
                </div>
            </div>
        </div>
        <div class="analysis-bottom">
            <span class="big-title">省市排名Top10</span>
            <div class="flex">
                <span class="title" :class="{current: areaCurrent === '省份'}" @click="toggleArea('省份')">省份分布</span>
                <span class="title" :class="{current: areaCurrent === '城市'}" @click="toggleArea('城市')" style="margin-left: 20px">城市分布</span>
            </div>
            <div class="area-distribution">
                <div class="title">
                    <span>名称</span>
                    <span>占比</span>
                </div>
                <div class="content">
                    <div v-if="areaDistribution.length === 0" class="no-data">暂无数据</div>
                    <template v-else>
                        <div class="area-item" v-for="item in areaDistribution">
                            <span class="area-name">{{item.title}}</span>
                            <el-progress :percentage="item.rate" :show-text="false" :stroke-width="12"></el-progress>
                            <span class="percentage">{{item.value}}%</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { newAccountVideoPerson } from '@/utils/apis.js'
    export default {
        data() {
            return {
                gender_summary: [],
                ageDistribution: null,
                showAgeData: true,
                areaDistribution: [],
                areaCurrent: '省份',
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            // 获取详情
            getInfo() {
                let params = {
                    aweme_id: this.$route.query.aweme_id,
                }
                newAccountVideoPerson(params).then(res => {
                    if (res.code === 200) {
                        let sexArr  = res.data.gender_summary
                        sexArr.sort((a, b) => {return b.rate - a.rate})
                        this.gender_summary = res.data.gender_summary

                        if (res.data.age_summary.length === 0) {
                            this.showAgeData = false
                        } else {
                            this.showAgeData = true
                            let ageData = res.data.age_summary.map(item => {
                                return {
                                    value: item.rate,
                                    name: item.keyword + '岁'
                                }
                            })
                            this.$nextTick(() => {
                                this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                                let resizeTimer = null;
                                window.onresize = () => {
                                    clearTimeout(resizeTimer);
                                    resizeTimer = setTimeout(() => {
                                        this.ageDistribution.resize();
                                    }, 100)
                                }
                                this.getAgeDistribution(ageData)
                            })
                        }

                        let areaList, sum
                        if (this.areaCurrent === '省份') {
                            areaList = res.data.province_summary
                            sum = res.data.province_summary[0].rate
                        } else {
                            areaList = res.data.city_summary
                            sum = res.data.city_summary[0].rate
                        }
                        for (let i in areaList) {
                            let obj = {
                                title: areaList[i].keyword,
                                rate: Number((areaList[i].rate / sum * 100 ).toFixed(2)),
                                value: Number((areaList[i].rate).toFixed(2))
                            }
                            this.areaDistribution.push(obj);
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },

            toggleArea(val) {
                this.areaDistribution = []
                this.areaCurrent = val
                this.getInfo()
            },

            // 年龄分布环形 eCharts
            getAgeDistribution(data) {
                this.ageDistribution.setOption({
                    legend: {
                        orient: 'vertical', // 设置图例的方向
                        right: 0,
                        top: 'center',
                    },
                    grid: {
                        top: 10,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                    series: [{
                        name: '职业分布',
                        type: 'pie',
                        center: ['40%', '50%'], //图的位置，距离左跟上的位置
                        radius : ['60%', '70%'],
                        data: data,
                        barWidth: 18,
                        color: ['#9ECB59', '#5F55F1', '#C3C3F4', '#F5697B', '#F5AF23', '#2dc079', '#4FC5EA', '#6C6FBF'], //设置对应块的数据
                        itemStyle:{
                            normal:{
                                label : {  //饼图图形上的文本标签
                                    show : true,  //平常不显示
                                    color: '#000',
                                    fontSize: '16',
                                    // formatter: '{c}%\n{b}',
                                    formatter: '{c}%',
                                },
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontWeight: 'bold',
                                }
                            },
                        }
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 20px;
        .analysis-top{
            display: flex;
            border-bottom: 5px solid #EEEEEE;
            padding-bottom: 34px;
            margin: 0 30px;
            .analysis-left {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                border-right: 5px solid #EEEEEE;
                padding: 0 54px 0 24px;
                box-sizing: border-box;
                .gender-distribution {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .title {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .distribution-chart {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        height: 1%;
                        justify-content: center;
                    }
                }
            }
            .analysis-right {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 100%;
                .age-distribution {
                    .title {
                        padding-left: 32px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                }
            }
        }
        .analysis-bottom{
            display: flex;
            flex-direction: column;
            padding: 0 34px;
            margin-top: 40px;
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                cursor: pointer;
                &.current {
                    color: #0000FF;
                }
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .area-distribution {
                flex: 1;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);
                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }
                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
    }

    .sex-box {
        display: flex;
        width: 100%;
        .sex-item {
            text-align: center;
            /*min-width: 30px!important;*/
            & > .text {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 40px;
            }
            .bar {
                height: 12px;
                border-radius: 6px;
            }
            .bottom {
                margin-top: 40px;
                .iconfont {
                    width: 30px;
                    text-align: center;
                    display: inline-block;
                    font-size: 30px;
                }
            }
            &:last-child {
                text-align: right;
            }
            &:first-child {
                text-align: left;
            }
        }
    }

    .no-data {
        height: 20px;
        margin: 20px 0;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .big-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
</style>