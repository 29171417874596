<template>
  <div class="contain_box">
    <div class="goods-info" v-if="videoInfo.goods_info.length">
      <div class="info-left" @click="toDetail(videoInfo.goods_info[0])">
        <img :src="videoInfo.goods_info[0].image" alt="" />
      </div>
      <div class="info-right">
        <div class="right-item">{{ videoInfo.goods_info[0].title }}</div>
        <div class="right-item">价格：{{ videoInfo.goods_info[0].amount }}</div>
      </div>
    </div>
    <div class="middle_box" v-if="!isNoData">
      <div class="data-trend">
        <div class="data-menu-box">
          <span class="title">销量趋势</span>
          <!-- <div class="data-menu">
            <div class="item left" :class="{ selected: fanTabIndex === 0 }" @click="toggleFanTab(0)">增量</div>
            <div class="item right" :class="{ selected: fanTabIndex === 1 }" @click="toggleFanTab(1)">总量</div>
          </div> -->
        </div>
        <div class="content">
          <div id="goodsTrend" :style="{ width: '100%', height: '442px' }"></div>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>该视频暂无带货！</div>
  </div>
</template>

<script>
import { newAccountGetVideoGoodsAnalysis, getVideoGoodsChart } from "@/utils/apis";

export default {
  props: ["videoId", "author_id"],
  data() {
    return {
      //近7天/30天切换
      fanTabIndex: 0,
      videoInfo: {
        goods_info: [],
      },
      goodsTrend: null,
      isNoData: false,
    };
  },
  created() {
    this.getVideoDetail();
  },
  methods: {
    async getVideoDetail() {
      let param = {
        aweme_id: this.$route.query.aweme_id,
      };
      let [chartRes, goodsRes] = await Promise.all([getVideoGoodsChart(param), newAccountGetVideoGoodsAnalysis(param)]);

      if (chartRes.code == 200 && goodsRes.code == 200) {
        this.videoInfo.trend = chartRes.data.chart;
        this.videoInfo.goods_info = goodsRes.data;
        if (this.videoInfo.goods_info.length == 0) {
          this.isNoData = true;
        }
        if (this.videoInfo.trend) {
          this.goodsData();
        }
      } else {
        this.$message({
          type: "error",
          // message: res.message,
          message: "获取商品信息错误，请尝试刷新页面",
          duration: 1000,
        });
      }
      // newAccountGetVideoGoodsAnalysis(param).then(res => {
      //     if (res.code == 200) {
      //         //曲线图数据
      //         this.videoInfo = res.data.list
      //         if (this.videoInfo.length == 0) {
      //             this.isNoData = true
      //         }
      //         if (this.videoInfo.trend) {
      //             this.goodsData()
      //         }
      //     } else {
      //         this.$message({
      //             type: "error",
      //             message: res.message,
      //             duration: 1000
      //         });
      //     }
      // });
    },
    toggleFanTab(index) {
      this.fanTabIndex = index;
      this.goodsData();
    },
    goodsData() {
      this.$nextTick(() => {
        let arr = [],
          dataX = [],
          dataY = [];
        // if (this.fanTabIndex === 0) {
        //   arr = this.videoInfo.trend.trend_add;
        // } else {
        //   arr = this.videoInfo.trend.trend_total;
        // }
        arr = this.videoInfo.trend;
        arr.forEach((value, index) => {
          dataX.push(value.date);
          dataY.push(value.volume);
        });
        this.goodsTrend = this.$echarts.init(document.getElementById("goodsTrend"));
        let resizeTimer = null;
        window.onresize = () => {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(() => {
            this.goodsTrend.resize();
          }, 100);
        };
        this.goodsTrend.setOption({
          title: {
            x: "center",
            y: "bottom",
            textStyle: {
              //设置主标题风格
              Color: "#333", //设置主标题字体颜色
              fontSize: 14,
              fontStyle: "normal",
            },
          },
          tooltip: {
            trigger: "axis",
            padding: 0,
            axisPointer: {
              type: "line",
              lineStyle: {
                type: "solid",
                width: 1,
                color: "#e0e2eb",
              },
            },
            formatter: function (params) {
              let name = params[0].name;
              let date = params[0].data;
              let str =
                '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' +
                name +
                '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' +
                "销量数：" +
                '</span> <sapn class="tooltip-title-right blue"> ' +
                date +
                " </sapn>\
                            </div>\
                            </div>\
                            </div>";
              return str;
            },
          },
          xAxis: {
            type: "category",
            data: dataX,
            //设置网格线颜色
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
              },
            },
          },
          yAxis: {
            scale: true,
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#f1f1f1"],
              },
            },
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              fontSize: "14",
              marginTop: "35px",
              formatter: "{value}",
              show: true,
            },
          },
          series: [
            {
              data: dataY,
              type: "line",
              lineStyle: {
                color: "#857FDD",
                width: 3,
              },
              smooth: true,
              symbol: "none",
            },
          ],
        });
      });
    },
    // 去详情页
    toDetail(item) {
      this.$router.push({
        path: "/student/dy/goodDetail",
        query: {
          id: item.promotion_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.contain_box {
  height: 100%;

  .goods-info {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 60px;

    .info-left {
      height: 100px;
      cursor: pointer;

      img {
        max-height: 100%;
      }
    }

    .info-right {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .right-item {
        margin: 20px;
      }
    }
  }

  .cancel_margin {
    padding: 0px;
    margin: 0;
  }

  .bold_font {
    font-weight: bold;
    font-size: 25px;
  }

  .center {
    margin: auto;
    text-align: center;
  }

  .data-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: center;

    .item {
      cursor: pointer;
      width: 50px;
      height: 30px;
      background: #f1f0fe;
      display: flex;
      justify-content: center;
      align-items: center;

      &.left {
        border-radius: 5px 0px 0px 5px;
      }

      &.right {
        border-radius: 0px 5px 5px 0px;
      }
    }

    .selected {
      color: #ffffff;
      background: #564ced;
    }
  }
}

.data-trend {
  margin-top: 20px;

  .data-menu-box {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  ::v-deep.tooltip {
    height: 59px;
    border-radius: 2px;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
    background: #fff;

    .tooltip-box {
      padding: 13px 20px 10px;
    }

    .tooltip-title {
      font-size: 12px;
      color: #999999;
    }

    .tooltip-title-right {
      float: right;
      padding-right: 10px;
      font-size: 16px;

      &.blue {
        color: #564ced;
      }
    }

    .tooltip-title-left {
      color: #333;
    }
  }
}

.no-data {
  height: 20px;
  margin: 20px 0;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
