<template>
  <div class="contain_box">
    <div class="main_box">
      <div class="data-trend">
        <div class="data-menu-box">
          <span class="title">点赞趋势</span>
          <div class="data-menu">
            <div class="item left" :class="{ selected: diggTabIndex === 0 }" @click="toggleDiggTab(0)">增量</div>
            <div class="item right" :class="{ selected: diggTabIndex === 1 }" @click="toggleDiggTab(1)">总量</div>
          </div>
        </div>
        <div class="content">
          <div id="diggTrend" :style="{ width: '100%', height: '442px' }"></div>
        </div>
      </div>
      <div class="data-trend">
        <div class="data-menu-box">
          <span class="title">转发趋势</span>
          <div class="data-menu">
            <div class="item left" :class="{ selected: shareTabIndex === 0 }" @click="toggleShareTab(0)">增量</div>
            <div class="item right" :class="{ selected: shareTabIndex === 1 }" @click="toggleShareTab(1)">总量</div>
          </div>
        </div>
        <div class="content">
          <div id="shareTrend" :style="{ width: '100%', height: '442px' }"></div>
        </div>
      </div>
      <div class="data-trend">
        <div class="data-menu-box">
          <span class="title">评论趋势</span>
          <div class="data-menu">
            <div class="item left" :class="{ selected: commentTabIndex === 0 }" @click="toggleCommentTab(0)">增量</div>
            <div class="item right" :class="{ selected: commentTabIndex === 1 }" @click="toggleCommentTab(1)">总量</div>
          </div>
        </div>
        <div class="content">
          <div id="commentTrend" :style="{ width: '100%', height: '442px' }"></div>
        </div>
      </div>
      <div class="data-trend">
        <div class="data-menu-box">
          <span class="title">播放量</span>
          <div class="data-menu">
            <div class="item left" :class="{ selected: playTabIndex === 0 }" @click="togglePlayTab(0)">增量</div>
            <div class="item right" :class="{ selected: playTabIndex === 1 }" @click="togglePlayTab(1)">总量</div>
          </div>
        </div>
        <div class="content">
          <div id="playTrend" :style="{ width: '100%', height: '442px' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newAccountGetVideoBashAnalysis, getVideoPlayCount } from "@/utils/apis";
import { getFormatDate } from "@/utils/formatDate";

export default {
  // props: ["videoId"],
  data() {
    return {
      videoData: {},
      tableData: [],
      addData: [],
      totalData: [],
      diggTabIndex: 0,
      shareTabIndex: 0,
      commentTabIndex: 0,
      playTabIndex: 0,
      // diggTrend:null,
      // shareTrend:null,
      // commentTrend:null,

      /**图表差异配置项 */
      chartConfig: {
        like: {
          colors: ["#333333", "#e0e2eb", "#999999", "#f1f1f1", "#999999", "#857FDD"],
          text: "点赞数",
          timplateClass: ["purple"],
        },
        share: {
          colors: ["#333333", "#e0e2eb", "#999999", "#f1f1f1", "#999999", "#04A8FF"],
          text: "转发数",
          timplateClass: ["blue"],
        },
        comment: {
          colors: ["#333333", "#e0e2eb", "#999999", "#f1f1f1", "#999999", "#857FDD"],
          text: "评论数",
          timplateClass: ["purple"],
        },
        play: {
          colors: ["#333333", "#e0e2eb", "#999999", "#f1f1f1", "#999999", "#04A8FF"],
          text: "播放量",
          timplateClass: ["blue"],
        },
      },
      //存放echarts对象
      likeChart: null,
      shareChart: null,
      commentChart: null,
      playChart: null,
      //echarts图表的时间和数据分为两个数组, 节省每次图表更新的循环开销
      likeTrendData: {
        increment: {
          list: [],
          times: [],
        },
        totalQuantity: {
          list: [],
          times: [],
        },
      },
      shareTrendData: {
        increment: {
          list: [],
          times: [],
        },
        totalQuantity: {
          list: [],
          times: [],
        },
      },
      commentTrnedData: {
        increment: {
          list: [],
          times: [],
        },
        totalQuantity: {
          list: [],
          times: [],
        },
      },
      playTrnedData: {
        increment: {
          list: [],
          times: [],
        },
        totalQuantity: {
          list: [],
          times: [],
        },
      },
    };
  },
  mounted() {
    this.getVideoDetail();
  },

  methods: {
    async getVideoDetail() {
      let param = {
        aweme_id: this.$route.query.aweme_id,
      };
      //获取点赞转发评论数据
      let res = await newAccountGetVideoBashAnalysis(param);
      if (res.code == 200) {
        let setData = (type, item, index) => {
          let time = getFormatDate(new Date(item.update_time * 1000));
          time = time.split(" ")[1].substr(0, 5);
          this.likeTrendData[type].list.push(item.digg_count);
          this.likeTrendData[type].times.push(time);
          this.shareTrendData[type].list.push(item.share_count);
          this.shareTrendData[type].times.push(time);
          this.commentTrnedData[type].list.push(item.comment_count);
          this.commentTrnedData[type].times.push(time);
        };
        if (res.data.add) {
            res.data.add.forEach((item, index) => setData("increment", item, index));
        }
        if (res.data.total) {
            res.data.total.forEach((item, index) => setData("totalQuantity", item, index));
        }


        this.chartInit();
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: 1000,
        });
      }
      //获取播放量数据
      res = await getVideoPlayCount(param);
      if (res.code == 200) {
        let setData = (type, item, index) => {
          let time = getFormatDate(new Date(item.update_time * 1000));
          time = time.split(" ")[1].substr(0, 5);
          this.playTrnedData[type].list.push(item.play_count);
          this.playTrnedData[type].times.push(time);
        };
        res.data.add.forEach((item, index) => setData("increment", item, index));
        res.data.total.forEach((item, index) => setData("totalQuantity", item, index));
        this.togglePlayTab(0);
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: 1000,
        });
      }
    },
    /**初始化图表 */
    chartInit() {
      this.$nextTick(() => {
        this.likeChart = this.$echarts.init(document.getElementById("diggTrend"));
        this.shareChart = this.$echarts.init(document.getElementById("shareTrend"));
        this.commentChart = this.$echarts.init(document.getElementById("commentTrend"));
        this.playChart = this.$echarts.init(document.getElementById("playTrend"));
        this.toggleDiggTab(0);
        this.toggleShareTab(0);
        this.toggleCommentTab(0);
        let resizeTimer = null;
        window.onresize = () => {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(() => {
            this.likeChart.resize();
            this.shareChart.resize();
            this.commentChart.resize();
            this.playChart.resize();
          }, 100);
        };
      });
    },

    diggFun(data) {
      this.setChart(data, this.likeChart, this.chartConfig.like);
    },
    shareFun(data) {
      this.setChart(data, this.shareChart, this.chartConfig.share);
    },
    commentFun(data) {
      this.setChart(data, this.commentChart, this.chartConfig.comment);
    },
    playHandler(data) {
      this.setChart(data, this.playChart, this.chartConfig.play);
    },

    toggleDiggTab(index) {
      this.diggTabIndex = index;
      this.diggTabIndex == 0 ? this.diggFun(this.likeTrendData.increment) : this.diggFun(this.likeTrendData.totalQuantity);
    },
    toggleShareTab(index) {
      this.shareTabIndex = index;
      this.shareTabIndex == 0 ? this.shareFun(this.shareTrendData.increment) : this.shareFun(this.shareTrendData.totalQuantity);
    },
    toggleCommentTab(index) {
      this.commentTabIndex = index;
      this.commentTabIndex == 0 ? this.commentFun(this.commentTrnedData.increment) : this.commentFun(this.commentTrnedData.totalQuantity);
    },
    togglePlayTab(index) {
      this.playTabIndex = index;
      this.playTabIndex == 0 ? this.playHandler(this.playTrnedData.increment) : this.playHandler(this.playTrnedData.totalQuantity);
    },
    /**根据传入的参数配置图表 */
    setChart(data, chartView, config) {
      this.$nextTick(() => {
        let { list, times } = data;
        let { text, colors, timplateClass } = config;
        chartView.setOption(
          {
            title: {
              x: "center",
              y: "bottom",
              textStyle: {
                //设置主标题风格
                Color: colors[0], //设置主标题字体颜色
                fontSize: 14,
                fontStyle: "normal",
              },
            },
            tooltip: {
              trigger: "axis",
              padding: 0,
              axisPointer: {
                type: "line",
                lineStyle: {
                  type: "solid",
                  width: 1,
                  color: colors[1],
                },
              },
              formatter: function (params) {
                let name = params[0].name;
                let date = params[0].data;
                let str = `
                    <div class="tooltip">
                      <div class="tooltip-box">
                        <div class="tooltip-title">${name}</div>
                        <div class="tooltip-content">
                          <span class="tooltip-title-left">${text}</span> 
                          <sapn class="tooltip-title-right ${timplateClass.join(" ")}">${date}</sapn>
                        </div>
                      </div>
                    </div>
                  `;
                return str;
              },
            },
            xAxis: {
              data: times,
              //设置网格线颜色
              boundaryGap: false,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2],
                },
              },
            },
            yAxis: {
              scale: true,
              type: "value",
              splitLine: {
                show: true,
                lineStyle: {
                  color: [colors[3]],
                },
              },
              //隐藏刻度线
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: colors[4],
                },
              },
              axisLabel: {
                fontSize: "14",
                marginTop: "35px",
                formatter: "{value}",
                show: true,
              },
            },
            series: [
              {
                data: list,
                type: "line",
                lineStyle: {
                  color: colors[5],
                  width: 3,
                },
                smooth: true,
                symbol: "none",
              },
            ],
          },
          true
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.contain_box {
  height: 100%;

  .head_title {
    height: 80px;
    display: flex;
    justify-content: space-between;

    .left_title {
      margin-left: 40px;
    }
  }

  .main_box {
    padding-top: 20px;
    margin-left: 40px;
  }

  .current {
    color: #20c997;
  }
}

.data-trend {
  margin-top: 20px;

  .data-menu-box {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  ::v-deep.tooltip {
    height: 59px;
    border-radius: 2px;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
    background: #fff;

    .tooltip-box {
      padding: 13px 20px 10px;
    }

    .tooltip-title {
      font-size: 12px;
      color: #999999;
    }

    .tooltip-title-right {
      float: right;
      padding-right: 10px;
      font-size: 16px;

      &.blue {
        color: #04a8ff;
      }

      &.purple {
        color: #857fdd;
      }
    }

    .tooltip-title-left {
      color: #333;
    }
  }
}

.data-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;

  .item {
    cursor: pointer;
    width: 50px;
    height: 30px;
    background: #f1f0fe;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      border-radius: 5px 0px 0px 5px;
    }

    &.right {
      border-radius: 0px 5px 5px 0px;
    }
  }

  .selected {
    color: #ffffff;
    background: #564ced;
  }
}
</style>
